//
// forms
//
//


// group
label {
	margin-bottom: .25rem;
	font-size: 12px;
	text-transform: uppercase;
	color: $gray-300;
	letter-spacing: $letter-spacing-lg;
}


// .input-group-prepend {
// 	margin-right: 0;
// }



//
// input group
//
// .input-group {
// 	background: rgba($black, .1);

// 	.form-control {
// 		background: transparent;
// 	}
// }


//
// minimal
//
.form-control-minimal {
	background: transparent;
	border-radius: 0;
	border: 0;
	border-bottom: $border-width solid $border-color;

	&::placeholder {
		color: $input-placeholder-color;
	}

	&:focus {
		background: transparent;
	}
}



//
// text-whiteed colors
//
.text-white {

	// input group
	// .input-group {
	// 	background: rgba($black, .1);
		
	// 	.form-control {
	// 		background: transparent;
	// 	}
	// }

	// .form-group {
	// 	label {
	// 		color: rgba($white, .4);
	// 	}
	// }

	.form-control {
		border-color: transparent;
		background: rgba($white, .1);
		color: $text-white-primary;

		// placeholder
		&::placeholder {
			color: rgba($white, .5);
		}

		&:focus {
			border-color: $text-white-primary;
		}
	}

	// .form-control-minimal {
	// 	border-bottom: $border-width solid $text-white-border;

	// 	&::placeholder {
	// 		color: rgba($white, .5);
	// 	}
	// }


	// .input-group-text {
	// 	background-color: $text-white-border;
	// }

}