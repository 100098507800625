// forms
//
//
//

.custom-control-label {
  &::before {
    @include transition;
    border: $border-width solid $border-color;
    background: transparent;
  }
}


.custom-checkbox {
  .custom-control-input:checked ~ .custom-control-label {
    &::before {
      border-color: $primary;
      background-color: $primary;
    }
  }
}


.custom-radio {
  .custom-control-input:checked ~ .custom-control-label {
    &::before {
      border-color: $primary;
      background-color: $primary;
    }
    &::after {
      background-image: none;
    }
  }
}


.text-white {
  .custom-control-label {
    &::before {
      border-color: $text-white-border;
      background: transparent;
    }
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: $text-white-primary;
    background-color: $text-white-primary;
  }
}


//
// custom select
//
select {
  opacity: 0!important;
}

$selectric-main-color:      #DDD !default;                                              // Color used in border
$selectric-secondary-color: #BBB !default;                                              // Color used in button
$selectric-text-color:      #444 !default;                                              // Color used in label
$selectric-bg-color:        #F8F8F8 !default;                                           // Background color
$selectric-height:          40px !default;                                              // Outer height
$selectric-spacing:         10px !default;                                              // Label left padding
$selectric-border-width:    1px !default;                                               // Outer border width
$selectric-inner-height:    $selectric-height - ($selectric-border-width * 2) !default; // Inner height

.selectric-wrapper {
  position: relative;
  cursor: pointer;
}

.selectric-responsive {
  width: 100%;
}


// select
.selectric {
  @include transition;
  @include border-radius;
  border: $input-border-width solid $input-border-color;
  background-color: transparent;
  position: relative;

  .label {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    margin: 0;
    text-overflow: ellipsis;
    padding: $input-padding-y $input-padding-x;
    line-height: $input-line-height;
    color: $input-color;
    user-select: none;
  }

  .button {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: ($input-padding-y * 2 + $input-line-height);
    text-align: center;
    font: 0/0 a;
    *font: 20px/#{$selectric-inner-height} Lucida Sans Unicode, Arial Unicode MS, Arial;

    &:after {
      content: " ";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      width: 0;
      height: 0;
      border: 4px solid transparent;
      border-top-color: $selectric-secondary-color;
      border-bottom: none;
    }
  }
}



// small
.selectric-form-control-sm {
  .selectric {
    .label {
      font-size: $font-size-sm;
      line-height: $btn-line-height-sm;
      padding: $btn-padding-y-sm;
    }
  }

  .selectric-items {
    li {
      padding: $input-padding-y-sm $input-padding-x-sm;
      font-size: $font-size-sm;

      &:before {
        margin-top: -.25rem;
        width: .5rem;
        height: .5rem;
      }
    }
  }
}


// large
.selectric-form-control-lg {
  .selectric {
    .label {
      font-size: $font-size-lg;
      line-height: $btn-line-height-lg;
      padding: $btn-padding-y-lg;
    }
  }

  .selectric-items {
    li {
      padding: $input-padding-y-lg $input-padding-x-lg;
      font-size: $font-size-lg;
    }
  }
}

.selectric-open {
  z-index: 9999;

  .selectric {
    border-color: transparent;
  }

  .selectric-items {
    display: block;
  }
}

.selectric-disabled {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default;
  user-select: none;
}

.selectric-hide-select {
  position: relative;
  overflow: hidden;
  width: 0;
  height: 0;

  select {
    position: absolute;
    left: -100%;
    display: none;
  }
}

.selectric-input {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  outline: none !important;
  border: none !important;
  *font: 0/0 a !important;
  background: none !important;
}

.selectric-temp-show {
  position: absolute !important;
  visibility: hidden !important;
  display: block !important;
}

/* Items box */
.selectric-items {
  @extend %boxed;
  @extend %floating;
  @include border-radius;
  display: none;
  position: absolute;
  top: $border-width;
  left: $border-width;
  right: $border-width;
  border: 0;
  z-index: 1;

  .selectric-scroll {
    height: 100%;
    overflow: auto;
  }

  .selectric-above & {
    top: auto;
    bottom: 0;
  }

  ul, li {
    list-style: none;
    padding: 0;
    margin: 0;
    line-height: $input-line-height;
  }

  li {
    @include transition;
    position: relative;
    display: block;
    padding: $input-padding-y $input-padding-x;
    line-height: $input-line-height;
    font-size: $font-size-base;
    color: $input-color;
    cursor: pointer;

    &:before {
      position: absolute;
      top: 50%;
      right: $input-padding-x;
      margin-top: -.4rem;
      content: "";
      display: block;
      width: .8rem;
      height: .8rem;
      border-radius: 50%;
      background: $gray-200;
    }

    &:first-child {
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
    }

    &:last-child {
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }

    &:not(:first-child) {
      border-top: $border-width solid $border-color;
    }

    &.selected,
    &.selected:hover {
      &:before {
        background: $primary;
      }
    }

    &:hover {
      background: $gray-100;
    }
  }

  .disabled {
    filter: alpha(opacity=50);
    opacity: 0.5;
    cursor: default !important;
    background: none !important;
    color: #666 !important;
    user-select: none;
  }

  .selectric-group {
    .selectric-group-label {
      font-weight: bold;
      padding-left: 10px;
      cursor: default;
      user-select: none;
      background: none;
      color: #444;
    }

    &.disabled li {
      filter: alpha(opacity=100);
      opacity: 1;
    }

    li {
      padding-left: 25px;
    }
  }
}

//
// minimal
//
.selectric-form-control-minimal {
  .selectric {
    border-radius: 0;
    border: 0;
    border-bottom: $border-width solid rgba($black, .1);
  }

  .selectric-items {
    border: 0;
  }
}


.text-white {
  .selectric {
    border-color: transparent;
    background: rgba($white, .1);
  }

  .selectric-items {
    li {
      color: $body-color;

      &.selected,
      &.selected:hover {
        color: $primary;
      }
    }
  }
}