//
// List
//


// .list-group {
// 	font-size: 0.9375rem;
// }


.list-group-item {
	position: relative;

	span {
		margin-bottom: 0;
	}

	[class*="icon"] {
		&:first-child {
			padding-right: 1rem;
		}
		
		&:last-child {
			padding-left: 1rem;
		}
	}

	a {
		@include transition();
		display: inline-block;
		color: inherit;

		&:hover {
			color: $primary;
		}
	}
}


// .list-group-item-action {
// 	@include transition;

// 	@include hover-focus {
// 		background-color: transparent;
// 	}
// }


.list-group-line {
	.list-group-item {
		padding-left: 0;
		padding-right: 0;
		border: 0;

		&:not(:first-child) {
			&::before {
				position: absolute;
				top: 0;
				content: "";
				display: block;
				width: 25%;
				height: $border-width;
				background: $border-color;
			}
		}
	}
}


// minimal
.list-group-minimal {
	.list-group-item {
		padding: 0 0 .5em 0;
		border: 0;

		&:last-child {
			padding: 0;
		}

		&.active {
			background: transparent;
			color: $black;
		}

		[class*="icon"] {
			padding-right: 0;
		}
	}
}


.text-white {
	.list-group-item {

		&:not(:first-child) {
			border-color: $text-white-border;
		}

		a {
			color: $text-white-secondary;

			&:hover {
				color: $text-white-primary;
			}
		}

		span {
			color: $text-white-primary;
		}
	}
}