// Progress
// 
//
//


//
// Progress Bar
//

.progress {
	@include border-radius(.25rem);
	overflow: visible;

	.progress-bar {
		@include border-radius(.25rem);
		position: relative;

		span {
			@include transition();
			position: absolute;
			right: 0;
			top: calc(100% + .5rem);
			font-size: 12px;
			font-weight: $font-weight-normal;
			line-height: 1rem;
		}
	}
}

.progress-title {
	margin-bottom: 1em;
	font-size: $font-size-sm;
	font-weight: 600;
	letter-spacing: $letter-spacing;
	text-transform: uppercase;
}

%progress-text-white {
	.progress {
		background: rgba($white, .1);
	}

	.progress-bar {
		background: $text-white-primary;

		span {
			color: $text-white-primary;
		}
	}
}

.progress-circle {
	position: relative;

	canvas {
		height: 100%!important;
		width: 100%!important;
	}

	strong {
		@include translate(-50%, -50%);
		position: absolute;
		font-size: 2.5rem;
		font-family: $headings-font-family;
		font-weight: 400;
		top: 50%;
		left: 50%;
	}
}