@import 'tables';
@import 'navbar';
@import 'hero';
@import 'swiper';
@import 'boxes';
@import 'gallery';
@import 'nav';
@import 'features';
@import 'accordion';
@import 'carousel';
@import 'users';
@import 'blog';
@import 'pricing';
@import 'buttons';
@import 'partners';
@import 'testimonials';
@import 'jobs';
@import 'list';
@import 'video';
@import 'cards';
@import 'maps';
@import 'icons';
@import 'dropdowns';
@import 'syntax';
@import 'docs';
@import 'badges';
@import 'breadcrumbs';
@import 'masonry';
@import 'presentations';
@import 'progress';
@import 'modals';